(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name navigation.controller:NavigationCtrl
     *
     * @description
     *
     */
    angular.module('navigation').controller('NavigationCtrl', NavigationCtrl);

    function NavigationCtrl($localStorage, $scope, $uibModal) {
        var vm = this;
        const _superAdminIds = [5, 7, 2, 10, 11, 92, 25, 122, 123];
        vm.ctrlName = 'NavigationCtrl';

        $scope.modal = {};
        vm.isSuperAdmin = _superAdminIds.includes(
            $localStorage.loggedInUser.id
        );

        vm.userTypeModal = function () {
            $scope.modal.title = 'View site as:';
            $scope.modal.body =
                "Select the user type you'd like to view the site as";
            $scope.modal.userType = {};
            $scope.modal.hasDismiss = true;
            $uibModal.open({
                animation: true,
                templateUrl: 'modal/modal.tpl.html',
                size: 'md',
                controller: 'ModalCtrl',
                scope: $scope,
            });
        };
    }
})();
